import { Button } from '@material-ui/core';
import ImageUploading from "react-images-uploading";
import React from 'react';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import Bottombar from './Bottombar.js';


const Convert = () => {
    const [images, setImages] = React.useState([]);
    const maxNumber = 69;
    const onChange = (imageList) => {
      setImages(imageList);
    }

    return (
        <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageRemove,
          isDragging,
          dragProps
        }) => (
          <div className="parent">
            <div className="upload__image-wrapper">
              
              <Box component="span" m={1} onClick={onImageUpload} {...dragProps} className="uploadBox">
                <AddIcon fontSize="large"></AddIcon>
                <span className="uploadText">Drop files</span>
              </Box>
              <div className="mainButtonContainer">              
              <Button variant="contained" color="primary"
                style={isDragging ? { color: "red" } : null}
                onClick={onImageUpload}
                {...dragProps}
                className="mainButton"
              >
                Upload
              </Button>
              <Button color="default" variant="outlined" className={imageList.length > 0 ? 'mainButton' : 'hidden mainButton'} onClick={onImageRemoveAll}>Remove All</Button>
              </div>
              <canvas id="canvas"></canvas>
              <div id="uploadContainer">
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image.data_url} alt="" width="" id={image.file.name} className="original"/>
                <div class="thumbnail">
                  <img src={image.data_url} alt="" width="150" id="thumbnail"/>
                </div>
                <div className="image-item__btn-wrapper">
                  <Button variant="outlined" color="secondary" onClick={() => onImageRemove(index)}>Remove</Button>
                  <Button variant="contained" color="primary" onClick={() => {
                      let canvas = document.getElementById('canvas');
                      let ctx = canvas.getContext('2d');
                      let img = document.getElementById(image.file.name)
                      canvas.width = img.width;
                      canvas.height = img.height;
                      ctx.drawImage(img, 0, 0);
                      let file = canvas.toDataURL();
                      let tmpLink = document.createElement('a');  
                      tmpLink.download = image.file.name + '.png';
                      tmpLink.href = file;  
                      document.body.appendChild(tmpLink);  
                      tmpLink.click();  
                      document.body.removeChild(tmpLink);
                  }}>Convert</Button>
                  
                </div>
                
              </div>
              
            ))}
            
            </div>
            <Bottombar />
            </div>
            
          </div>
          
        )}
      </ImageUploading>

    )

}
export default Convert;