import React from 'react';
import './styles.css';

const Bottombar = () => {
    return (
        <div className="Bottombar">
            <div className="Bottombar-container">
                <div className="Bottombar-logo">
                </div>
                <nav className="Bottombar-nav">
                    <ul className="Bottombar-links">
                        <li className="Nav-item">
                            <a href="/">
                               © 2021 convertli.io
                            </a>
                        </li>
                       
                    </ul>
                </nav>
            </div>
        </div>
    ) 

}
export default Bottombar;