import React from 'react';
import './styles.css';

const Topbar = () => {
    return (
        <div className="Topbar">
            <div className="Topbar-container">
                <div className="Topbar-logo">
                </div>
                <nav className="Topbar-nav">
                    <ul className="Topbar-links">
                        <li className="Nav-item">
                            <a href="/">
                                About
                            </a>
                        </li>
                        <li className="Nav-item">
                            <a href="/">
                                Contact
                            </a>
                        </li>
                       
                    </ul>
                </nav>
            </div>
        </div>
    ) 

}
export default Topbar;